<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import dayjs from 'dayjs';
import { useField } from 'vee-validate';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

const props = defineProps<{
  name: string;
  type: 'dateTime' | 'date';
  label?: string;
  required?: boolean;
  hideDetails?: boolean;
  rules?: Record<string, any>;
  disabled?: boolean;
  disabledDates?: { start?: Date; end?: Date; interval?: number }[];
}>();

// Data
const isOpen = ref(false);
const { value, errorMessage } = useField<string>(props.name);

const button = ref<HTMLElement | null>(null);
const picker = ref<HTMLElement | null>(null);

// Methods
onClickOutside(picker, () => (isOpen.value = false));

// Computed
const getDisabledDates = computed(() => {
  const disabledDates: { start?: Date; end?: Date }[] = [];
  if (props.disabledDates && props.disabledDates.length > 0) {
    disabledDates.push(...props.disabledDates);
    return disabledDates;
  }
  return [];
});
</script>

<template>
  <div ref="inputContainer" class="w-full relative" :class="[hideDetails ? '' : 'mb-[16px]']">
    <label v-if="label" :class="errorMessage ? 'text-red-300' : 'text-gray-600'" class="font-medium text-xs mb-[6px] block text-left">
      {{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </label>
    <div class="w-full relative">
      <!-- input -->
      <DatePicker
        v-model="value"
        color="green"
        :mode="type"
        is24hr
        :rules="rules"
        :disabled-dates="getDisabledDates"
        @popover-will-show="isOpen = true"
        @popover-will-hide="isOpen = false"
        @update:model-value="type === 'date' ? (isOpen = false) : ''"
      >
        <template #default="{ togglePopover }">
          <div
            ref="button"
            :class="[
              errorMessage ? 'border-red-600' : 'border-gray-200  hover:ring-[1px]',
              isOpen
                ? errorMessage
                  ? 'border-red-600 ring-red-100 ring-[2px]'
                  : 'border-primary-500 ring-green-100 ring-[2px]'
                : errorMessage
                  ? ''
                  : 'hover:border-gray-300 hover:ring-gray-100',
              disabled ? '!border-gray-100' : '',
            ]"
            class="flex z-0 h-[32px] bg-white items-center w-full cursor-pointer justify-start border rounded-md px-2 pr-5 text-sm font-medium group-hover:text-gray-700 text-gray-600"
            @click="togglePopover()"
          >
            <ui-icon name="Calendar" class="w-4 mr-2" :stroke-width="2" :default-class="isOpen ? 'stroke-primary' : '!stroke-gray-300'" />
            <template v-if="value">
              {{ type === 'date' ? dayjs(value).format('DD/MM/YYYY') : dayjs(value).format('DD/MM/YYYY HH:mm') }}
            </template>
            <template v-else>
              <span class="text-gray-400 font-normal">
                {{ $t('global.select_a_date') }}
              </span>
            </template>
          </div>
        </template>
      </DatePicker>

      <span class="absolute right-0 top-[34px] text-red-500 text-xs">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>
